

























































































































































































































import { debounceProcess } from "@/helpers/debounce";
import { ResponsePagination } from "@/models/constant/interface/common.interface";
import { DEFAULT_DATE_FORMAT } from "@/models/constants/date.constant";
import { Messages } from "@/models/enums/messages.enum";
import {
  ContactData,
  ResponseListMaster,
} from "@/models/interface/contact.interface";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { DataWarehouseBranch } from "@/models/interface/logistic.interface";
import {
  DataResponseGetListPurchaseOrder,
  DataResponseGetListPurchaseOrderReport,
} from "@/models/interface/purchase.interface";
import { DataMasterCurrency } from "@/models/interface/settings.interface";
import { contactServices } from "@/services/contact.service";
import { logisticServices } from "@/services/logistic.service";
import { masterServices } from "@/services/master.service";
import { purchaseServices } from "@/services/purchase.service";
import { settingsServices } from "@/services/settings.service";
import moment, { Moment } from "moment";
import { Component, Vue } from "vue-property-decorator";

interface LoadingComponent {
  loadingBranch: boolean;
  loadingFind: boolean;
  loadingSupplier: boolean;
  loadingPurchaseOrderNumber: boolean;
  loadingPurchaseOrderType: boolean;
  loadingDownload: boolean;
  loadingCurrency: boolean;
}

@Component
export default class ReportPurchaseOrder extends Vue {
  DEFAULT_DATE_FORMAT = DEFAULT_DATE_FORMAT;
  form = this.$form.createForm(this, { name: "reportPurchaseOrder" });
  page = 1;
  limit = 10;
  totalData = 0;
  params: RequestQueryParamsModel = {};
  loading: LoadingComponent = {
    loadingBranch: false,
    loadingFind: false,
    loadingSupplier: false,
    loadingPurchaseOrderNumber: false,
    loadingPurchaseOrderType: false,
    loadingDownload: false,
    loadingCurrency: false,
  };
  dataBranch: DataWarehouseBranch[] = [];
  dataSupplier: ContactData[] = [];
  dataCurrency: DataMasterCurrency[] = [];
  dataPurchaseOrderType: ResponseListMaster[] = [];
  dataPurchaseOrderNumber: DataResponseGetListPurchaseOrder[] = [];
  dataSource: DataResponseGetListPurchaseOrderReport[] = [];
  filterForm = {
    date: null as Moment[] | null,
  };

  columnsTable = [
    {
      title: "No.",
      dataIndex: "no",
      key: "no",
      width: 110,
      scopedSlots: { customRender: "isNull" },
    },
    {
      title: this.$t("lbl_branch"),
      dataIndex: "branchName",
      key: "branchName",
      width: 170,
      scopedSlots: { customRender: "isNull" },
    },
    {
      title: this.$t("lbl_purchase_order_type"),
      dataIndex: "poType",
      key: "poType",
      width: 170,
      scopedSlots: { customRender: "isNull" },
    },
    {
      title: this.$t("lbl_purchase_order_number"),
      dataIndex: "poNumber",
      key: "poNumber",
      width: 170,
      scopedSlots: { customRender: "isNull" },
    },
    {
      title: this.$t("lbl_pr_number"),
      dataIndex: "prNumber",
      key: "prNumber",
      width: 170,
      scopedSlots: { customRender: "isNull" },
    },
    {
      title: "Supplier Code",
      dataIndex: "supplierCode",
      key: "supplierCode",
      width: 170,
      scopedSlots: { customRender: "isNull" },
    },
    {
      title: this.$t("lbl_supplier_name"),
      dataIndex: "supplierName",
      key: "supplierName",
      width: 170,
      scopedSlots: { customRender: "isNull" },
    },
    {
      title: this.$t("lbl_currency"),
      dataIndex: "currency",
      key: "currency",
      width: 170,
      scopedSlots: { customRender: "isNull" },
    },
    {
      title: "Rates PO",
      dataIndex: "rates",
      key: "rates",
      width: 170,
      scopedSlots: { customRender: "isCurrency" },
    },
    {
      title: "PO Amount (DPP)",
      dataIndex: "poAmount",
      key: "poAmount",
      width: 170,
      scopedSlots: { customRender: "isCurrency" },
    },
    {
      title: "PO Tax Amount",
      dataIndex: "poTaxAmount",
      key: "poTaxAmount",
      width: 170,
      scopedSlots: { customRender: "isCurrency" },
    },
    {
      title: "Total PO Amount (IDR)",
      dataIndex: "totalPOAmount",
      key: "totalPOAmount",
      width: 170,
      scopedSlots: { customRender: "isCurrency" },
    },
    {
      title: "Rates Invoice",
      dataIndex: "ratesInvoice",
      key: "ratesInvoice",
      width: 170,
      scopedSlots: { customRender: "isCurrency" },
    },
    {
      title: "Invoice Amount (DPP)",
      dataIndex: "invoiceAmount",
      key: "invoiceAmount",
      width: 170,
      scopedSlots: { customRender: "isCurrency" },
    },
    {
      title: "Invoice Tax Amount",
      dataIndex: "invoiceTaxAmount",
      key: "invoiceTaxAmount",
      width: 170,
      scopedSlots: { customRender: "isCurrency" },
    },
    {
      title: "Total Invoice Amount (IDR)",
      dataIndex: "totalInvoiceAmount",
      key: "totalInvoiceAmount",
      width: 170,
      scopedSlots: { customRender: "isCurrency" },
    },
  ];

  formRules = {
    branch: {
      label: "lbl_branch",
      name: "branch",
      placeholder: "lbl_branch_placeholder",
      decorator: [
        "branch",
        {
          rules: [
            {
              required: false,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    supplierName: {
      label: "lbl_supplier_name",
      name: "supplierName",
      placeholder: "lbl_supplier_name_placeholder",
      decorator: [
        "supplierName",
        {
          rules: [
            {
              required: false,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    date: {
      label: "lbl_date",
    },
    currency: {
      label: "lbl_currency",
      name: "currency",
      placeholder: "lbl_currency_placeholder",
      decorator: [
        "currency",
        {
          rules: [
            {
              required: false,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    purchaseOrderType: {
      label: "lbl_purchase_order_type",
      name: "purchaseOrderType",
      placeholder: "lbl_purchase_order_type_placeholder",
      decorator: [
        "purchaseOrderType",
        {
          rules: [
            {
              required: false,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    purchaseOrderNumber: {
      label: "lbl_purchase_order_number",
      name: "purchaseOrderNumber",
      placeholder: "lbl_purchase_order_number_placeholder",
      decorator: [
        "purchaseOrderNumber",
        {
          rules: [
            {
              required: false,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
  };

  handleDownload() {
    this.form.validateFields((err, res) => {
      if (err) return;
      const params: RequestQueryParamsModel = {};
      params.search = this.dynamicSearch(res);
      params.params = this.checkParams(res);
      this.loading.loadingDownload = true;
      purchaseServices
        .downloadPurchaseOrder(params)
        .then((response) => {
          if (response) {
            const url = window.URL.createObjectURL(new Blob([response]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "purchase_order.xlsx"); //or any other extension
            document.body.appendChild(link);
            link.click();
          }
        })
        .finally(() => (this.loading.loadingDownload = false));
    });
  }

  responsePageSizeChange(response: ResponsePagination): void {
    this.limit = response.size;
    this.page = 1;
    this.handleFind(false);
  }

  responseCurrentPageChange(response: ResponsePagination): void {
    this.page = response.page;
    this.handleFind(false);
  }

  checkValue(value): string {
    if (value && !value.includes("undefined")) {
      return value;
    } else {
      return ",ALL";
    }
  }

  checkParams(res): string {
    let params = "";
    // company is mandatory
    params += this.$store.state.authStore.authData.companyName;
    params += this.checkValue(
      `,${
        this.dataBranch.find((dataFind) => dataFind.id === res["branch"])?.name
      }`
    );
    params += this.checkValue(
      `,${this.dataSupplier
        .find((dataFind) => dataFind.id === res["supplierName"])
        ?.fullName?.replaceAll(",", "")}`
    );

    if (this.filterForm.date && this.filterForm.date.length > 0) {
      params += this.checkValue(
        `,${moment(this.filterForm.date[0]).format(
          DEFAULT_DATE_FORMAT
        )},${moment(this.filterForm.date[1]).format(DEFAULT_DATE_FORMAT)}`
      );
    } else {
      params += `,ALL`;
      params += `,ALL`;
    }
    params += this.checkValue(`,${res["currency"]}`);
    params += this.checkValue(`,${res["purchaseOrderType"]}`);
    params += this.checkValue(`,${res["purchaseOrderNumber"]}`);
    return params;
  }

  assignSearch(key, value, and): string {
    if (key === "branch" && value) return and + `branch.secureId~${value}`;
    else if (key === "supplierName" && value)
      return and + `supplier.secureId~${value}`;
    else if (key === "purchaseOrderNumber" && value)
      return and + `documentNumber~*${value}*`;
    else if (key === "purchaseOrderType" && value)
      return and + `poType~${value}`;
    else if (key === "currency" && value)
      return and + `priceCurrency.currencyCode~${value}`;
    // jika key = status
    else return "";
  }

  dynamicSearch(res): string {
    let search = "";
    if (this.filterForm.date && this.filterForm.date.length > 0) {
      search = `date>=${moment(this.filterForm.date[0])
        .set({ hour: 0, minute: 0, second: 0 })
        .utcOffset("+07")
        .format()}_AND_date<=${moment(this.filterForm.date[1])
        .set({ hour: 23, minute: 59, second: 59 })
        .utcOffset("+07")
        .format()}`;
    }
    Object.keys(res).forEach((key) => {
      if (!search) {
        search = this.assignSearch(key, res[key], "");
      } else {
        search += this.assignSearch(key, res[key], "_AND_");
      }
    });
    return search;
  }

  handleFind(firstSearch) {
    this.form.validateFields((err, res) => {
      if (err) return;
      const params: RequestQueryParamsModel = {
        page: this.page - 1,
        limit: this.limit,
        sorts: "createdDate:desc",
      };
      if (firstSearch) {
        params.page = 0;
        this.page = 1;
      }
      params.search = this.dynamicSearch(res);
      this.loading.loadingFind = true;
      purchaseServices
        .getListPurchaseOrderReport(params)
        .then((response) => {
          this.totalData = response.totalElements;
          this.dataSource = response.data.map((dataMap, index) => {
            return {
              ...dataMap,
              key: index,
              no:
                response.currentPage === 0
                  ? index + 1
                  : this.limit * (this.page - 1) + index + 1,
              date: moment(dataMap.date).format(DEFAULT_DATE_FORMAT),
            };
          });
        })
        .finally(() => (this.loading.loadingFind = false));
    });
  }

  responseViewTable(response) {
    this.$router.push(`/purchasing/purchase-order/detail/${response.data.id}`);
  }

  getBranch(valueSearch) {
    const params: RequestQueryParamsModel = {
      page: 0,
      limit: 10,
    };
    if (valueSearch)
      params.search = `name~*${valueSearch}*_OR_code~*${valueSearch}*_OR_address~*${valueSearch}`;
    this.loading.loadingBranch = true;
    logisticServices
      .listWarehouseBranch(params, "")
      .then((response) => {
        this.dataBranch = response.data;
      })
      .finally(() => (this.loading.loadingBranch = false));
  }

  getSupplier(valueSearch) {
    const params: RequestQueryParamsModel = {
      page: 0,
      limit: 10,
      search: `supplier~true`,
    };
    if (valueSearch)
      params.search = `supplier~true_AND_firstName~*${valueSearch}*_OR_lastName~*${valueSearch}*_AND_supplier~true`;
    this.loading.loadingSupplier = true;
    contactServices
      .listContactData(params)
      .then((res) => {
        this.dataSupplier = res.data;
      })
      .finally(() => (this.loading.loadingSupplier = false));
  }

  getCurrency(valueSearch) {
    const params: RequestQueryParamsModel = {
      page: 0,
      limit: 10,
    };
    if (valueSearch) params.search = `currencyCode~${valueSearch}`;
    this.loading.loadingCurrency = true;
    settingsServices
      .listOfMasterCurrency(params, "")
      .then((response) => (this.dataCurrency = response.data))
      .finally(() => (this.loading.loadingCurrency = false));
  }

  getPurchaseOrderType(valueSearch) {
    const params: RequestQueryParamsModel = {
      page: 0,
      limit: 10,
      name: "PURCHASE_ORDER_TYPE",
    };
    if (valueSearch) params.search = `value~*${valueSearch}*`;
    this.loading.loadingPurchaseOrderType = true;
    masterServices
      .listMaster(params)
      .then((response) => {
        this.dataPurchaseOrderType = response;
      })
      .finally(() => (this.loading.loadingPurchaseOrderType = false));
  }

  getPurchaseOrderNumber(valueSearch) {
    const params: RequestQueryParamsModel = {
      page: 0,
      limit: 10,
      sorts: "createdDate:desc",
    };
    if (valueSearch) params.search = `documentNumber~*${valueSearch}*`;
    this.loading.loadingPurchaseOrderNumber = true;
    purchaseServices
      .getListPurchaseOrder(params)
      .then((response) => {
        this.dataPurchaseOrderNumber = response.data.filter(
          (dataFilter) => dataFilter.documentNumber
        );
      })
      .finally(() => (this.loading.loadingPurchaseOrderNumber = false));
  }

  filterOption(input, option) {
    return (
      option.componentOptions.children[0].componentOptions.children[1].text
        .toLowerCase()
        .indexOf(input.toLowerCase()) >= 0
    );
  }

  created() {
    this.getBranch = debounceProcess(this.getBranch, 400);
    this.getSupplier = debounceProcess(this.getSupplier, 400);
    this.getCurrency = debounceProcess(this.getCurrency, 400);
    this.getPurchaseOrderNumber = debounceProcess(
      this.getPurchaseOrderNumber,
      400
    );
    this.getPurchaseOrderType = debounceProcess(this.getPurchaseOrderType, 400);
    this.getBranch("");
    this.getSupplier("");
    this.getCurrency("");
    this.getPurchaseOrderNumber("");
    this.getPurchaseOrderType("");
  }

  get formItemLayout() {
    return {
      labelCol: { span: 8 },
      wrapperCol: { span: 14 },
    };
  }
}
